import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { postAPI } from "api";

import { CurrentUser } from "api/types";

function submitCredentials(
  credentials: CredentialResponse,
): Promise<CurrentUser> {
  return postAPI("/api/auth/credentials", credentials);
}

function Auth({ onSuccess }: { onSuccess: (whoami: CurrentUser) => void }) {
  const handleSuccess = (response: CredentialResponse) => {
    submitCredentials(response)
      .then((whoami) => onSuccess(whoami))
      .catch((err) => console.log(err));
  };

  const handleError = () => {
    console.log("Google login failed.");
  };

  return <GoogleLogin onSuccess={handleSuccess} onError={handleError} />;
}

export default Auth;
