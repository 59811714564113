import { usePathname } from "next/navigation";
import { NavItem } from "@bagel-web/components";

export const getNavConfig = (): NavItem[] => {
  return [
    {
      href: "/organizations",
      iconClassName: "bi-building",
      label: "Organizations",
    },
    {
      href: "/users",
      iconClassName: "bi-person",
      label: "Users",
    },
    {
      href: "/sessions",
      iconClassName: "bi-clock-history",
      label: "Sessions",
    },
    {
      href: "/chalk-logs",
      iconClassName: "bi-clipboard-fill",
      label: "Chalk logs",
    },
    {
      href: "/api-requests",
      iconClassName: "bi-arrow-left-right",
      label: "API requests",
    },
    {
      href: "/feature-flags",
      iconClassName: "bi-flag-fill",
      label: "Feature flags",
    },
    {
      href: "/documentation",
      iconClassName: "bi-book-fill",
      label: "Docs",
    },
  ];
};

export const getIsActive = (navItem: NavItem) => {
  const pathname = usePathname();
  return pathname?.startsWith(navItem.href);
};
